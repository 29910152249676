import { FC, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import Navbar, { MenuElement } from '@components/Navbar';
import { LS_AUTH_KEY } from '@config/consts';
import { graphql } from '@gql/gql';

const ACCOUNT_QUERY = graphql(`
  query Account {
    account {
      imageUrl
      email
      name
    }
  }
`);

interface Props {
  menu: Array<MenuElement>;
  title: string;
}

const Layout: FC<Props> = ({ menu, title }) => {
  const { data, loading, error } = useQuery(ACCOUNT_QUERY);
  const navigate = useNavigate();
  
  async function handleSignOut() {
    localStorage.removeItem(LS_AUTH_KEY);
    localStorage.removeItem('impersonate');
    navigate('/oauth/signin');
  }

  const userMenuElements = useMemo(() => {
    return [
      {
        name: 'Esci',
        onClick: handleSignOut,
      },
    ];
  }, []);

  if (error) throw error;

  return (
    <Flex direction="column" w="full" h="full">
      <Navbar
        title={title}
        navElements={menu}
        userMenuElements={userMenuElements}
        loading={loading}
        avatarName={data?.account.name}
        avatarSrc={data?.account.imageUrl}
      />
      <Box flexGrow={1}>
        <Outlet />
      </Box>
    </Flex>
  );
};

export default Layout;
